<template>
  <div class="e-form-container">
    <div class="e-form-header">
      <div class="nav-logo-ctn">
        <img src="../../assets/kyx_logo.png" class="nav-logo" />
      </div>
      <div class="operations">
        <div v-if="mode === 'edit'" :class="[`undo-ctn`, `operation`, recordLength > 0 ? '' : `disable-operation`]"
          @click="undoClick">
          <div>
            <svg-icon name="undo" class="icon"></svg-icon>
          </div>
          <div>
            撤销
          </div>
        </div>
        <div v-if="mode === 'edit'" :class="[`redo-ctn`, `operation`, redoLength > 0 ? `` : `disable-operation`]"
          @click="redoClick">
          <div>
            <svg-icon name="redo" class="icon"></svg-icon>
          </div>
          <div>
            重做
          </div>
        </div>
        <div v-if="mode === 'edit'" class="gap"></div>
     <!--    <div class="report-ctn operation" @click="makeReportButtonClick">
          <div>
            <svg-icon name="report" class="icon"></svg-icon>
          </div>
          <div>
            实验报告
          </div>
        </div> -->
        <a-dropdown v-if="mode === 'edit'" :disabled="!isInputFocus" placement="bottomCenter">
          <div :class="[`time-ctn`, `operation`, isInputFocus ? '' : 'disable-operation']">
            <div>
              <svg-icon name="time" class="icon"></svg-icon>
            </div>
            <div>
              时间戳
            </div>
          </div>
          <a-menu slot="overlay">
            <a-menu-item @click="insertTime('YYYY-MM-DD HH:mm:ss')">
              年月日 时分秒
            </a-menu-item>
            <a-menu-item @click="insertTime('YYYY-MM-DD HH:mm')">
              年月日 时分
            </a-menu-item>
            <a-menu-item @click="insertTime('YYYY-MM-DD')">
              年月日
            </a-menu-item>
            <a-menu-item @click="insertTime('HH:mm:ss')">
              时分秒
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <div class="gap"></div>
        <div class="fullscreen-ctn operation" @click="fullscreenClick">
          <div>
            <svg-icon name="fullscreen" class="icon"></svg-icon>
          </div>
          <div>
            全屏
          </div>
        </div>
        <div v-if="mode === 'edit'" class="shutdouwn-ctn operation" @click="handleShutDown">
          <div>
            <svg-icon name="shutdown" class="icon"></svg-icon>
          </div>
          <div>
            保存并关闭
          </div>
        </div>
      </div>
    </div>
    <div class="e-form-content" ref="KFDC" style="background: #ffffff;">
      <a-spin :spinning="loading" tip="正在加载中...." :delay="300">
        <k-form-design ref="KFD" @recordChange="handleRecordChange" @redoChange="handleRedoChange"
          @dataChange="handleDataChange" :mode="mode" @experimentFormChange="experimentFormChange"
           :type="`template`" :id="id" :industrySectorList="industrySectorList"
          :dataList="dataList" :experimentDetail="experimentDetail" @onAddExperimentTypeClick="onAddExperimentTypeClick"/>
      </a-spin>
    </div>
    <make-report-modal :visible.sync="makeReportModalVisible" @confirmMakeReport="handleConfirmMakeReport">
    </make-report-modal>
    <submitting-mask :fileSubmitting="fileSubmitting" :formSubmitting="formSubmitting"></submitting-mask>
    <add-new-experiment-type :visible.sync="addExperimentTypeModalVisible" @onSuccess="handleAddExperimentTypeSuccess">
    </add-new-experiment-type>
  </div>
</template>

<style lang="less" scoped>
.e-form-container {
  background: #f6f6f6;
  color: rgba(0, 0, 0, 0.45);
}

.e-form-header {
  height: 60px;
  background: #ffffff;
  padding: 10px;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;

  .nav-logo-ctn {
    margin-left: 6%;
  }

  .nav-logo {
    height: 40px;
  }

  .operations {
    margin-left: 14%;
    font-size: 12px;
    display: flex;

    .operation {
      text-align: center;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    .operation+.operation {
      margin-left: 25px;
    }

    .icon {
      font-size: 24px;
      color: #4E5969;
      fill: #4E5969;
    }

    .gap {
      height: 60px;
      background: #f6f6f6;
      width: 3px;
      margin-left: 25px;
      margin-right: 35px;
      transform: translateY(-10px);
    }

    .disable-operation {
      .icon {
        fill: #ccc !important;
        color: #ccc !important;
      }

      color: #ccc !important;
      cursor: not-allowed;
    }
  }
}

.e-form-content {
  margin-top: 1px;
  z-index: 99;
}
</style>
<script>
import { useFullscreen, useThrottleFn } from '@vueuse/core';
import { computed, onMounted, ref } from '@vue/composition-api';
import MakeReportModal from '../experiment/components/makeReportModal.vue';
import { insertTime } from '../../plugins/KFormDesign/utils';
import { message, Modal } from 'ant-design-vue';
import SubmittingMask from '../experiment/components/submittingMask.vue';
import { getExperimentTemplateInitData, getTemplateDetail, editExperimentTemplate } from '../../api/experimentTemplate';
import { getShareExperimentTemplateDetail } from '../../api/shareExperimentTemplate';
import router from '../../router';
import AddNewExperimentType from './components/addNewExperimentType.vue';


//import KFormComponentP
export default {
  components: {
    MakeReportModal,
    SubmittingMask,
    AddNewExperimentType
},
  setup(props, context) {
    const KFD = ref(null)
    const KFDC = ref(null)
    const { isFullscreen, toggle } = useFullscreen(KFDC)

    // 模式
    const mode = ref(context.root.$route.query.mode ? context.root.$route.query.mode : 'edit')
    // 实验id
    const id = ref(context.root.$route.query.id ? context.root.$route.query.id : null);
    // 分享实验id
    const shareId = ref(context.root.$route.query.shareId ? context.root.$route.query.shareId : undefined);
    // 加载
    const loading = ref(false);
    // 实验数据列表
    const formData = ref({
      experimentId: '',
      experimentNo: '',
      title: '',
      keyWord: '',
      roomTemperature: '',
      relativeHumidity: '',
      conclusion: ''
    })
    // 全部实验详情数据
    const experimentDetail = ref({

    })
    // 项目其他用户
    const projectUsers = ref([]);
    // 实验类型列表
    const dataList = ref([]);
    // 行业领域列表
    const industrySectorList = ref([]);
    // 关闭前的操作
    const beforeShutDown = (event) => {
      var event = event || window.event;
      if (event) {
        event.returnValue = "确定要关闭窗口吗？";
      }
      return '确定要关闭窗口吗?';
    }
    // 初始化实验模板form
    const initTemplateForm = ref({

    });
    // 初始化实验模板content
    const initTemplateContent = ref({

    });
    // 模板数据是否发生改变
    const isTemplateChange = computed(() => {
      if (KFD.value) {
        const isContentChange = JSON.stringify(initTemplateContent.value) !== JSON.stringify(eData.value);
        const isFormChange = JSON.stringify(initTemplateForm.value) !== JSON.stringify(KFD.value.experimentTemplateForm)
        return isContentChange || isFormChange
      }
      return false
    })
    // 初始化数据
    const initPage = async () => {
      loading.value = true;
      if (mode.value === 'edit') {
        window.onbeforeunload = beforeShutDown;
      }
      const initRes = await getExperimentTemplateInitData();
      if (initRes.code === 200) {
        dataList.value = initRes.data.dataList;
        industrySectorList.value = initRes.data.industrySectorList;
      }
      if (id.value) {
        const res = await getTemplateDetail({
          Id: id.value
        });
        if (res.code === 200) {
          initTemplateContent.value = JSON.parse(res.data.content);
          KFD.value && (KFD.value.handleSetData(JSON.parse(res.data.content)))
          KFD.value && (KFD.value.experimentTemplateForm = {
            name: res.data.name,
            description: res.data.description,
            experimentType: res.data.experimentType,
            industrySector: res.data.industrySector.split(',')
          });

          if (KFD.value) {
            for (let i = 0; i < KFD.value.experimentTemplateForm.industrySector.length; ++i) {
              KFD.value.experimentTemplateForm.industrySector[i] = parseInt(KFD.value.experimentTemplateForm.industrySector[i]);
            }
            initTemplateForm.value = JSON.parse(JSON.stringify(KFD.value.experimentTemplateForm));
          }
        }
      }
      if(shareId.value) {
        const res = await getShareExperimentTemplateDetail({
          Id: shareId.value
        });
        if (res.code === 200) {
          initTemplateContent.value = JSON.parse(res.data.content);
          KFD.value && (KFD.value.handleSetData(JSON.parse(res.data.content)))
          KFD.value && (KFD.value.experimentTemplateForm = {
            name: res.data.name,
            description: res.data.description,
            experimentType: res.data.experimentType,
            industrySector: res.data.industrySector.split(',')
          });

          if (KFD.value) {
            for (let i = 0; i < KFD.value.experimentTemplateForm.industrySector.length; ++i) {
              KFD.value.experimentTemplateForm.industrySector[i] = parseInt(KFD.value.experimentTemplateForm.industrySector[i]);
            }
            initTemplateForm.value = JSON.parse(JSON.stringify(KFD.value.experimentTemplateForm));
          }
        }
      }
      loading.value = false;
    }
    onMounted(async () => {
      initPage();
      if (mode.value === 'preview' || mode.value === 'pdf') {
      }
    })

    const handleTest = () => {
      toggle()
    }
    const handlePDf = () => {
      KFD.value && KFD.value.handleSave();
    }

    const recordLength = ref(0);  // 记录长度
    const redoLength = ref(0);  // 撤销表长度
    // 记录表长度变化回调
    const handleRecordChange = (length) => {
      recordLength.value = length;
    }
    // 撤销表长度变化回调
    const handleRedoChange = (length) => {
      redoLength.value = length;
    }
    // 电子实验记录表数据
    const eData = ref({
      ist: [],
      config: {
        //layout: "horizontal",
        layout: "vertical",
        labelCol: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
        labelWidth: 100,
        labelLayout: "flex",
        wrapperCol: { xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 },
        hideRequiredMark: false,
        customStyle: ""
      }
    })
    // 电子实验记录表数据变化
    const handleDataChange = (e) => {
      eData.value = e;
    }
    // 实验表格数据变化
    const experimentFormChange = (e) => {
      formData.value = e
    }
    // 撤销点击事件
    const undoClick = () => {
      if (!recordLength) {
        return
      }
      KFD.value && KFD.value.handleUndo();
    }
    // 前进点击事件
    const redoClick = () => {
      if (!redoLength) {
        return
      }
      KFD.value && KFD.value.handleRedo();
    }
    // 全屏点击
    const fullscreenClick = () => {
      toggle()
    }

    /**********************           实验报告  start                   **********************/
    // 实验报告对话框可见变量
    const makeReportModalVisible = ref(false);
    // 实验报告按钮点击事件
    const makeReportButtonClick = () => {
      makeReportModalVisible.value = true;
    }
    // 生成实验报告确认事件
    const handleConfirmMakeReport = (fileName) => {
      //console.log(fileName)
      KFD.value && KFD.value.handleSave(fileName)
    }
    /**********************           实验报告  end                     **********************/



    /**********************            时间戳  start                    *********************/
    // 当聚焦在可插入的位置的input实例上时，时间戳下拉事件显示
    const isInputFocus = computed(() => {
      return context.root.$store.getters.isInputFocus;
    });
    const test = () => {
      const instance = context.root.$store.getters.inputInstance;
    }
    /**********************            时间戳  end                      *********************/
    /**********************          点击关闭  start                    *********************/
    // 文件上传中
    const fileSubmitting = ref(false);
    const formSubmitting = ref(false);
    // 点击关闭， 使用节流
    const handleShutDown = useThrottleFn(async () => {
      const submitContent =  getAllExcel();
      if (isTemplateChange.value) {
        formSubmitting.value = true;
        if (KFD.value && KFD.value.$refs.experimentTemplateForm) {
          KFD.value && KFD.value.$refs.experimentTemplateForm.validate(async (valid) => {
            if (valid) {
              if (id.value) {
                const res = await editExperimentTemplate({
                  id: id.value,
                  ...KFD.value.experimentTemplateForm,
                  industrySector: KFD.value.experimentTemplateForm.industrySector.join(','),
                  content: submitContent
                });
                if (res.code === 200 || res.code === 204) {
                  //message.success(res.message + '，即将关闭');
                  Modal.success({
                    content: res.message,
                    okText: '关闭',
                    onOk: () => {
                      formSubmitting.value = false;
                      window.onbeforeunload = () => { };
                      window.close();
                      router.replace('/')
                      //context.root.$router.na
                    }
                  })
                }
                formSubmitting.value = false;
              }
              else {
                window.onbeforeunload = () => { };
                window.close();
              }
            }
            else {
              formSubmitting.value = false;
              message.error('模板信息有误，请仔细检查')
              KFD.value && (KFD.value.activeKey = 5);
            }
          })
        }
        else {
          if (id.value) {
            const res = await editExperimentTemplate({
              id: id.value,
              ...KFD.value.experimentTemplateForm,
              industrySector: KFD.value.experimentTemplateForm.industrySector.join(','),
              content: eData.value
            });
            if (res.code === 200 || res.code === 204) {
              message.success(res.message + '，即将关闭');
              formSubmitting.value = false;
              window.onbeforeunload = () => { };
              setTimeout(() => {
                window.close();
              }, 2000)
            }
          }
          else {
            window.onbeforeunload = () => { };
            window.close();
          }
        }
      }
      else {
        window.onbeforeunload = () => { };
        window.close();
      }


    }, 1000);

    // 获取所有的excel数据
        const getAllExcel = () => {
            const e = JSON.parse(JSON.stringify(eData.value))
            for (let i = 0; i < eData.value.list.length; ++i) {
                if (eData.value.list[i].type === 'excel-pro' && eData.value.list[i].getData) {
                    e.list[i].options.customData = eData.value.list[i].getData();
                    eData.value.list[i].options.customData =  e.list[i].options.customData;
                }
            }
            return e;
        }
    /**********************          点击关闭 end                      *********************/
    /**********************           添加实验类型                     *********************/
        // 点击添加实验类型回调
        const onAddExperimentTypeClick = () => {
            addExperimentTypeModalVisible.value = true;
        }
        // 添加实验类型对话框可见变量
        const addExperimentTypeModalVisible = ref(false);
        // 添加成功后回调
        const handleAddExperimentTypeSuccess = async (data) => {
            const initRes = await getExperimentTemplateInitData();
            if (initRes.code === 200) {
                dataList.value = initRes.data.dataList;
                industrySectorList.value = initRes.data.industrySectorList;
                KFD.value.activeKey = 5;
                KFD.value.setExperimentType(data)
            }
        }
        /**************************************************************************************/
    return {
      KFD,
      KFDC,
      handleTest,
      isFullscreen,
      toggle,
      handlePDf,
      handleRecordChange,
      handleRedoChange,
      redoLength,
      recordLength,
      undoClick,
      redoClick,
      fullscreenClick,
      makeReportModalVisible,
      makeReportButtonClick,
      handleDataChange,
      eData,
      handleConfirmMakeReport,
      isInputFocus,
      test,
      insertTime,
      handleShutDown,
      mode,
      experimentFormChange,
      experimentDetail,
      projectUsers,
      id,
      fileSubmitting,
      formSubmitting,
      loading,
      industrySectorList,
      dataList,
      formData,
      handleAddExperimentTypeSuccess,
      addExperimentTypeModalVisible,
      onAddExperimentTypeClick,
    }
  }
}
</script>